import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'Who we are',
        description = `Code² is a technology company that helps its clients develop software products.
        By combining diverse expertise within the company, we offer an end-to-end solution by taking care of everything from the beginning to the final solution.
        We are an experienced team of professionals that practice proactiveness and transparency in our work with clients. Our clients include companies from North America and Europe.`
        // description = 'We solve all technology related problems and provide insightful solutions with detailed analysis to help you in producing better results.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/avatars.jpg" alt="About Images"/>
                                </div>
                            </div>
                            <div className="service-area ptb--120 col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title text-center">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;