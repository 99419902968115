import React, { Component } from "react";
import { FiLayers, FiMonitor, FiDatabase, FiCloud, FiSmartphone, FiGlobe } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiGlobe />,
        title: 'Web development',
        description: 'Full stack web applications developed using the latest technologies. '
    },
    {
        icon: <FiDatabase />,
        title: 'Data engineering',
        description: 'We are experienced in building pipelines for various real-time and batch processing data flows.'
    },
    {
        icon: <FiSmartphone />,
        title: 'Mobile development',
        description: 'Android and iOS applications developed natively or using cross-platform frameworks.'
    },
    {
        icon: <FiLayers />,
        title: 'Machine Learning',
        description: 'We are most experienced in NLP but always interested in working on new domains.'
    },
    {
        icon: <FiMonitor />,
        title: 'Mendix app development',
        description: 'Our team includes Mendix certified developers.'
    },
    {
        icon: <FiCloud />,
        title: 'Cloud & DevOps',
        description: 'Amazon Web Services and Microsoft Azure are our platforms of choice.'
    }
]

class Service extends Component{
    render(){
        let title = 'Services';
        let description = `Code² is a data-driven company and believes that data-oriented applications are the future.
                           We know that this is just a part of the puzzle, so we offer various services that can produce great results.`

        return (
            <React.Fragment>
                 <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Service;