import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import { NavHashLink } from "react-router-hash-link";

class Header extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });

    window.addEventListener("scroll", this.handleScroll);
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  handleScroll(event) {
    if (window.scrollY > 0) {
      document.querySelector("#header").style.backgroundColor = "#1e1e25";
    } else {
      document.querySelector("#header").style.backgroundColor = "";
    }
  }

  render() {
    const { logo, color = "default-color" } = this.props;
    let logoUrl;
    if (logo === "light") {
      logoUrl = (
        <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />
      );
    } else if (logo === "dark") {
      logoUrl = (
        <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />
      );
    } else if (logo === "symbol-dark") {
      logoUrl = (
        <img
          src="/assets/images/logo/logo-symbol-dark.png"
          alt="Digital Agency"
        />
      );
    } else if (logo === "symbol-light") {
      logoUrl = (
        <img
          src="/assets/images/logo/logo-symbol-light.png"
          alt="Digital Agency"
        />
      );
    } else {
      logoUrl = (
        <img src="/assets/images/logo/c2logo2.png" alt="Digital Agency" />
      );
    }

    return (
      <header
        id="header"
        style={{ position: "fixed", top: 0 }}
        className={`header-area formobile-menu header--transparent header--static logoresize ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <NavHashLink
                    onClick={this.CLoseMenuTrigger}
                    scroll={(el) =>
                      setTimeout(
                        () => el.scrollIntoView({ behavior: "smooth" }),
                        0
                      )
                    }
                    smooth
                    to="/#"
                  >
                    Home
                  </NavHashLink>
                </li>
                <li>
                  <NavHashLink
                    onClick={this.CLoseMenuTrigger}
                    scroll={(el) =>
                      setTimeout(
                        () => el.scrollIntoView({ behavior: "smooth" }),
                        0
                      )
                    }
                    smooth
                    to="/#about"
                  >
                    About
                  </NavHashLink>
                </li>
                <li>
                  <NavHashLink
                    onClick={this.CLoseMenuTrigger}
                    scroll={(el) =>
                      setTimeout(
                        () => el.scrollIntoView({ behavior: "smooth" }),
                        0
                      )
                    }
                    smooth
                    to="/#services"
                  >
                    Services
                  </NavHashLink>
                </li>
                {/* <li><NavHashLink onClick={this.CLoseMenuTrigger} scroll={(el) => setTimeout(() => el.scrollIntoView({behavior: "smooth"}), 0)} smooth to="/#collaboration">Collaboration</NavHashLink></li> */}
                <li>
                  <NavHashLink
                    onClick={this.CLoseMenuTrigger}
                    scroll={(el) =>
                      setTimeout(
                        () => el.scrollIntoView({ behavior: "smooth" }),
                        0
                      )
                    }
                    smooth
                    to="/#techstack"
                  >
                    Tech stack
                  </NavHashLink>
                </li>
                {/* <li><Link to="" >Careers</Link></li> */}
              </ul>
            </nav>
            <div className="header-btn">
              <a className="rn-btn" href="/contact">
                <span>Contact us</span>
              </a>
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
