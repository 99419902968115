import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Service from "./components/Services";

import About from "./components/About";
import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";
import WrokModels from "./components/WorkModels";
import Technologies from "./components/Technologies";
import Slider from "./components/Slider";
import { FaExpeditedssl } from "react-icons/fa";

class HomePage extends Component {
  render() {
    return (
      <Fragment>
        <Helmet pageTitle="Code²" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="c2logo.png"
        />

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <Slider />
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div id="about" className="about-area about-position-top pb--120">
          <About />
        </div>
        {/* End About Area */}

        <div id="services" className="service-area ptb--80  bg_color--5">
          <div className="container">
            <Service />
          </div>
        </div>

        <div id="techstack">
          <Technologies className="bg_color--5" />
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer bgColor="bg_color--5" />
      </Fragment>
    );
  }
}
export default withRouter(HomePage);
