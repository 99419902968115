import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers ,FiChevronUp , FiCheck, FiX } from "react-icons/fi";

class WorkModels extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="rn-pricing-table-area ptb--120 bg_color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2 className="title">Models of Collaboration</h2>
                                    <p>We offer three main collaboration models. You can take a look below and decide which one is the best for you. If you have any questions, don't hesitate to contact us, we will be happy to help.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div style={{height: 570}}  className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Fixed Project Model</h4>
                                        </div>
                                        <div style={{"textAlign": "left"}} className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck/> Requires well-defined specifications</li>
                                                <li><FiX/> Frequent scope changes</li>
                                                <li><FiCheck/> Request for change goes through the change management procedure</li>
                                                <li><FiCheck/> Calculated total budget, timeline and risk</li>
                                                <li> <FiCheck/> Highlight and Scrum reports</li>
                                                <li><FiCheck/> Project roles are filled by our experts</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div style={{height: 570}} className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Agile Project Model</h4>
                                        </div>
                                        <div style={{"textAlign": "left"}} className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck/> Flexibility</li>
                                                <li><FiCheck/> Maximum budget and time control</li>
                                                <li><FiCheck/> Shortest prep time</li>
                                                <li><FiCheck/> Team of engineers</li>
                                                <li><FiCheck/> Deliver a full project</li>
                                                <li><FiCheck/> Collaboration with other teams</li>
                                                <li><FiCheck/> Daily status meetings and Bi-weekly review calls</li>
                                                <li><FiCheck/> Most appealing to clients</li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div style={{height: 570}} className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Dedicated Team Model</h4>
                                        </div>
                                        <div style={{"textAlign": "left"}} className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck/> Flexibility</li>
                                                <li><FiCheck/> Budget and time control</li>
                                                <li><FiCheck/> Shorter prep time</li>
                                                <li><FiCheck/> Greater client involvement</li>
                                                <li><FiCheck/> Team of engineers</li>
                                                <li><FiCheck/> Deliver a full project</li>
                                                <li><FiCheck/> Collaboration with other teams</li>
                                                <li><FiCheck/> Daily status meetings and Bi-weekly review calls</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default WorkModels;

