import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";

import { NavHashLink } from "react-router-hash-link";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/companies/code2tech",
  },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className={`footer-wrapper ${this.props.bgColor}`}>
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>Ready To Do This</span>
                    <h2>
                      Let's get <br /> to work
                    </h2>
                    <Link className="rn-button-style--2" to="/contact">
                      <span>Contact Us</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4>Quick Link</h4>
                        <ul className="ft-link">
                          <li>
                            <NavHashLink
                              scroll={(el) =>
                                setTimeout(
                                  () =>
                                    el.scrollIntoView({ behavior: "smooth" }),
                                  0
                                )
                              }
                              smooth
                              to="/#"
                            >
                              Home
                            </NavHashLink>
                          </li>
                          <li>
                            <NavHashLink
                              scroll={(el) =>
                                setTimeout(
                                  () =>
                                    el.scrollIntoView({ behavior: "smooth" }),
                                  0
                                )
                              }
                              smooth
                              to="/#about"
                            >
                              About
                            </NavHashLink>
                          </li>
                          <li>
                            <NavHashLink
                              scroll={(el) =>
                                setTimeout(
                                  () =>
                                    el.scrollIntoView({ behavior: "smooth" }),
                                  0
                                )
                              }
                              smooth
                              to="/#services"
                            >
                              Services
                            </NavHashLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h4>Write to us</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="mailto:info@codesquared.io">
                              info@codesquared.io
                            </a>
                          </li>
                          <li>
                            <a href="mailto:careers@codesquared.io">
                              careers@codesquared.io
                            </a>
                          </li>
                        </ul>

                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                            {SocialShare.map((val, i) => (
                              <li key={i}>
                                <a href={`${val.link}`}>{val.Social}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>
                          Copyright © {new Date().getFullYear()} Code2 doo. All
                          Rights Reserved. <br />
                          <a href="https://www.freepik.com/vectors/job-opportunity">
                            Job opportunity vector created by studiogstock -
                            www.freepik.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
