import React, { Component } from "react";
import { FiCode } from "react-icons/fi";

const ServiceList = [
    {
        icon: '01',
        title: 'Software development',
        description: 'We develop custom software based on your needs. Whether you have a start-up, a small business, or an enterprise, our skilled professionals would integrate seamlessly with your business.'
    },
    {
        icon: '02',
        title: 'Software maintenance and optimization',
        description: 'You already have a solution, but it needs to be optimized or further developed; you can leave that part to us.'

    },
    {
        icon: '03',
        title: 'Consulting',
        description: 'We offer our expertise to guide you through designing your product and overcoming technical problems. We will be here to help in any stage of your product development.'
    },
]

class ServiceGroups extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <FiCode/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceGroups;